<template>
    <div class="module-content" v-show="showList">
        <el-scrollbar class="module" :native="false" :vertical="true">
            <div class="module-list">
                <div :class="JSON.stringify(item)!=='{}'?'new-module-item':'module-item'"
                     v-for="(item,index) in module_list" :key="index">
                    <div class="item-cover1 course-ware">
                        <div class="show-img" v-if="item && item.show">
                            <img :src="showCourseList[item.show - 1].src" alt=""
                                 class="img-box">
                        </div>
                        <div v-show="JSON.stringify(item)!=='{}'" class="del-btn"
                             @click="delModule(item,index)">x
                        </div>
                    </div>
                </div>
                <draggable :list="select_list" :group="{name: 'resources'}"
                           style="display: flex;flex-direction: row;opacity: 0;position: absolute">
                    <div class="module-item" style="opacity: 0" v-for="(item,index) in select_list"
                         :key="index">
                        <div class="item-cover course-ware" :class="item?item.class:''">
                            <i class="iconfont" v-html="item?item.icon:''"></i>
                        </div>
                        <el-tooltip v-show="JSON.stringify(item)!=='{}'" effect="light"
                                    :content="item.name" placement="top" popper-class="tooltip">
                            <div class="show-title" @click="goToCourseWare(item)"></div>
                        </el-tooltip>
                    </div>
                </draggable>
            </div>
        </el-scrollbar>
        <el-dialog @close="closeTemplateBtn" custom-class="saveTemplate" title="保存模板"
                   :visible.sync="saveTemplateShow" append-to-body>
            <div class="template-box">
                <div class="template-box-name">
                    <span>模板名称：</span>
                    <el-input v-model="templateName" placeholder="输入名称3-10字"
                              style="width: 1%;flex: 1"></el-input>
                </div>
                <div class="template-box-btn">
                    <el-button @click="closeTemplateBtn">取消</el-button>
                    <el-button type="primary" @click="saveTemplateBtn">保存</el-button>
                </div>
            </div>
        </el-dialog>
        <div class="adhibition-btn" @click="useTempClick">
            <span>应用</span>
            <span>模板</span>
        </div>
        <div class="module-save-btn" @click="saveCourseCustom">
            <span>保存</span>
            <span>模板</span>
        </div>
        <div class="select-positioning">
            <!--                        <div class="hover-one">-->
            <!--                            <i class="iconfont">&#xe699;</i>-->
            <!--                        </div>-->
            <el-popover popper-class="temp-popover" ref="popoverRef" :disabled="tempPoverShow"
                        placement="top-end" width="280" trigger="click">
                <div class="popover-item" @click="selectTempClick(item)" v-for="item in courseTempList">
                    <span>{{item.name}}</span>
                    <i class="iconfont" @click.stop="deleTemp(item.id)">&#xe668;</i>
                </div>
                <div v-if="courseTempList.length === 0">暂无模板</div>
                <div class="hover-two" slot="reference">
                    <span>选择</span>
                    <span>模板</span>
                </div>
            </el-popover>
        </div>
    </div>
</template>

<script>
    import draggable from "vuedraggable";
    import _ from "underscore";
    import {courseGetTemplateData, courseDelTemplate} from "@/utils/apis";

    export default {
        name: "DraggableContent",
        props: ['showList', 'nodeIndex', 'chapterIndex', 'select_list', 'module_list', 'courseTempList', 'showCourseList'],
        components: {
            draggable
        },
        data() {
            return {
                templateName: '',
                saveTemplateShow: false,
                tempPoverShow: false,
            }
        },
        methods: {
            goToCourseWare(item) {
                this.$emit('goToCourseWare',item)
            },
            closeTemplateBtn() {
                    this.saveTemplateShow = false;
                this.templateName = ''
            },
            saveTemplateBtn() {
                if (this.templateName.length < 3 || this.templateName.length > 10) {
                    this.$message.warning('名称长度为3-10个字！');
                    return
                }
                this.$emit('handleSave', this.templateName)
                this.closeTemplateBtn()
            },
            useTempClick() {
                this.$emit('handleSave', 0)
            },
            saveCourseCustom() {
                let list = [];
                if (this.module_list.length) {
                    this.module_list.forEach((item, index) => {
                        if (JSON.stringify(item) !== '{}') {
                            list.push(item)
                        }
                    })
                }
                this.selectTemplateList = list;
                if (this.selectTemplateList.length > 0) {
                    this.saveTemplateShow = true;
                } else {
                    this.$message.warning('请选择至少一个模板！')
                }
            },
            selectTempClick(item) {
                this.$emit('handleTemp',item.id)
            },
            //删除模板
            deleTemp(id) {
                this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    courseDelTemplate({id: id}).then(res => {
                        this.$message({
                            message: '删除成功！',
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.$emit('updateTempList')
                            }
                        })
                    }).catch(err => {
                        console.error(err)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            delModule(item, index) {
                this.select_list[index] = {};
                this.module_list = this.select_list;
                this.$emit('handleList', this.select_list);
                this.$forceUpdate();
            },
        }
    }
</script>

<style scoped lang="scss">
    .module-content {
        display: flex;
        justify-content: space-between;
        padding: 19px 20px;
        /*position: absolute;*/
        /*bottom: 20px;*/
        /*left: 0;*/
        /*right: 0;*/
        align-items: center;
        box-shadow: 0px -5px 17px 3px rgba(4, 132, 70, 0.1);
        position: relative;
        background: #FFFFFF;

        .module {
            flex: 1;
            width: 1%;

            ::v-deep .el-scrollbar__wrap {
                overflow-y: hidden;
            }

            ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                white-space: nowrap;
            }
        }

        .module-list {
            padding: 6px;
            display: flex;
            position: relative;

            .new-module-item {
                position: relative;
                width: 60px;
                height: 60px;
                border-radius: 10px;
                margin-right: 15px;
            }

            .module-item {
                position: relative;
                width: 60px;
                height: 60px;
                border: 1px dashed #CCCCCC;
                border-radius: 24px;
                margin-right: 13px;
            }

            .item-cover {
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 50%;

                i {
                    font-size: 20px;
                    color: #fff;
                }
            }

            .item-cover1 {
                width: 60px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 50%;

                i {
                    font-size: 20px;
                    color: #fff;
                }

                .show-img {
                    width: 100%;
                    height: 100%;
                    box-shadow: 0px 2px 9px 1px rgba(87, 77, 237, 0.17);
                    border-radius: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .img-box {
                        width: 50px;
                        height: 50px;
                        border-radius: 24px;
                    }
                }
            }

            .show1 {
                background: #61BCEB;
            }

            .show2 {
                background: #3DCAC8;
            }

            .show3 {
                background: #F0BC49;
            }

            .show4 {
                background: #EC75A4;
            }

            .show5 {
                background: #79ACDC;
            }

            .show6 {
                background: #61BCEB;
            }

            .show7 {
                background: #5AB9E9;
            }

            .courseware {
                background: linear-gradient(0deg, #F5D971, #EDA92E);
            }

            .textbook {
                background: linear-gradient(0deg, #7BCBF2, #44ACE3);
            }

            .lesson-plan {
                background: linear-gradient(0deg, #53DED1, #2BB9BF);
            }

            .micro-lesson {
                background: linear-gradient(0deg, #EE89B5, #EA5D90);
            }

            .animation {
                background: linear-gradient(0deg, #F39E72, #EC6C6C);
            }

            .test-center {
                background: linear-gradient(0deg, #8CC5EC, #5F8AC7);
            }

            .training-center {
                background: linear-gradient(0deg, #ED83AA, #EC6B6F);
            }

            .knowledge-points {
                background: linear-gradient(0deg, #77C9F1, #47AEE4);
            }

            .material-library {
                background: linear-gradient(0deg, #51DCD0, #2CBAC0);
            }

            .case-library {
                background: linear-gradient(0deg, #F5D971, #EDA92E);
            }

            .related-copywriting {
                background: linear-gradient(0deg, #8BC3EB, #628EC9);
            }

            .personal-info {
                background: linear-gradient(0deg, #51DCD0, #2FBDC1);
            }

            .del-btn {
                width: 18px;
                height: 18px;
                background: #FF0000;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                position: absolute;
                right: 0;
                top: 0;
                opacity: 0;
                cursor: pointer;
                z-index: 1;
            }

            .show-title {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                z-index: 1;
                cursor: pointer;
            }

            .new-module-item:before {
                content: "";
                width: 0;
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                opacity: 0;
            }


            .new-module-item:hover:before {
                width: 100%;
                left: 0;
                opacity: 0.5;
            }

            .new-module-item:hover .del-btn {
                opacity: 1;
            }
        }

        .module-save-btn {
            width: 60px;
            height: 60px;
            background: #2DC079;
            border-radius: 10px;
            color: #FFFFFF;
            font-size: 14px;
            //line-height: 60px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            font-weight: 400;
            //margin-right: 20px;

            &:hover {
                cursor: pointer;
            }
        }

        .adhibition-btn {
            width: 60px;
            height: 60px;
            background: #2DC079;
            border-radius: 10px;
            color: #FFFFFF;
            font-size: 14px;
            text-align: center;
            font-weight: 400;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 20px;
        }
    }

    .temp-popover {
        display: flex;
        flex-direction: column;

        .no-data {
            text-align: center;
        }

        .popover-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            height: 30px;

            &:hover {
                background: #ECF5FF;

                .dragg-item {
                    color: #2DC079;
                }

                span {
                    color: #409EFF;
                }
            }

            div {
                color: #666666;
                font-size: 14px;
                padding-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            i {
                color: #666666;
                font-size: 12px;
                padding-right: 10px;
            }
        }
    }

    .select-positioning {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        box-shadow: 0px 1px 6px 1px rgba(64, 158, 255, 0.2);
        border-radius: 10px;
        margin-left: 20px;
        //position: absolute;
        //right: -20px;
        //top: -78px;
        //z-index: 99;
        background: #2DC079;

        i {
            font-size: 29px;
            color: #9CA9C3;
        }

        .hover-two {
            background: #2DC079;
            border-radius: 10px;
            color: #FFFFFF;
            font-size: 14px;
            //line-height: 60px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            font-weight: 400;
            cursor: pointer;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }

    ::v-deep .saveTemplate {
        width: 500px;

        .el-dialog__header {
            background: #2DC079;

            span {
                color: #fff;
                font-size: 16px;
            }

            .el-icon-close {
                color: #fff;
            }
        }

        .el-dialog__body {
            padding: 70px 0px 20px 50px;
        }

        .template-box {
            display: flex;
            flex-direction: column;

            .template-box-name {
                display: flex;
                align-items: center;
                color: #333333;
                font-size: 14px;
                padding-right: 60px;
            }

            .template-box-btn {
                margin-top: 70px;
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;

                .el-button {
                    margin-left: 20px;
                }
            }
        }
    }
</style>