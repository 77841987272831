import draggable from "vuedraggable";
import _ from "underscore";
import {getDataByCourseID, courseGetTemplateData, courseDelTemplate} from "@/utils/apis";
import DraggableContent from "../../../components/teacher/courseCenter/DraggableContent";

export default {
    name: "teacherFeatured",
    props: ['courseInfos', 'nodeIndex', 'chapterIndex', 'select_list', 'module_list', 'courseTempList'],
    components: {
        draggable,
        DraggableContent
    },
    data() {
        return {
            showResourseList: [],
            showPeitao: [],
            classId: 0,
            //选择的课程id
            select_course_id: this.$route.query.course_id,
            //精选课程
            featuredCourseList: [
                {
                    class: 'courseware',
                    icon: '&#xe677;',
                    src: require('../../../assets/images/teacher/course/courseware.png'),
                    title: '课件',
                    index: 1,
                    mtype: 0,
                },
                {
                    class: 'textbook',
                    icon: '&#xe687;',
                    src: require('../../../assets/images/teacher/course/textbook.png'),
                    title: '教材',
                    index: 2,
                    mtype: 0,
                },
                {
                    class: 'micro-lesson',
                    icon: '&#xe688;',
                    src: require('../../../assets/images/teacher/course/micro-lesson.png'),
                    title: '微课',
                    index: 3,
                    mtype: 0,
                },
                {
                    class: 'animation',
                    icon: '&#xe68e;',
                    src: require('../../../assets/images/teacher/course/animation.png'),
                    title: '教学设计',
                    index: 4,
                    mtype: 0,
                },
                {
                    class: 'person-resource',
                    icon: '&#xe65d;',
                    src: require('../../../assets/images/teacher/course/person-resource.png'),
                    title: '个人资源',
                    index: 5,
                    mtype: 0,
                },
                {
                    class: 'test-center',
                    icon: '&#xe678;',
                    src: require('../../../assets/images/teacher/course/practice-train.png'),
                    title: '实操训练',
                    index: 6,
                    mtype: 0,
                },
                {
                    class: 'training-center',
                    icon: '&#xe66f;',
                    src: require('../../../assets/images/teacher/course/exam-train.png'),
                    title: '考试训练',
                    index: 7,
                    mtype: 0,
                },
            ],
            showCourseList: [
                {
                    class: 'courseware',
                    icon: '&#xe677;',
                    src: require('../../../assets/images/teacher/course/courseware.png'),
                    title: '课件',
                    index: 1,
                    mtype: 0,
                },
                {
                    class: 'textbook',
                    icon: '&#xe687;',
                    src: require('../../../assets/images/teacher/course/textbook.png'),
                    title: '教材',
                    index: 2,
                    mtype: 0,
                },
                {
                    class: 'micro-lesson',
                    icon: '&#xe688;',
                    src: require('../../../assets/images/teacher/course/micro-lesson.png'),
                    title: '微课',
                    index: 3,
                    mtype: 0,
                },
                {
                    class: 'animation',
                    icon: '&#xe68e;',
                    src: require('../../../assets/images/teacher/course/animation.png'),
                    title: '教学设计',
                    index: 4,
                    mtype: 0,
                },
                {
                    class: 'peitao',
                    icon: '&#xe68e;',
                    src: require('../../../assets/images/teacher/course/peitao.png'),
                    title: '配套材料',
                    index: 5,
                    mtype: 0,
                },
                {
                    class: 'test-center',
                    icon: '&#xe678;',
                    src: require('../../../assets/images/teacher/course/practice-train.png'),
                    title: '实操训练',
                    index: 6,
                    mtype: 0,
                },
                {
                    class: 'training-center',
                    icon: '&#xe66f;',
                    src: require('../../../assets/images/teacher/course/exam-train.png'),
                    title: '考试训练',
                    index: 7,
                    mtype: 0,
                },
                {
                    class: 'person-resource',
                    icon: '&#xe65d;',
                    src: require('../../../assets/images/teacher/course/person-resource.png'),
                    title: '个人资源',
                    index: 8,
                    mtype: 0,
                },
            ],
            //是否显示上传资料弹窗
            dialogUploadMaterial: false,
            //上传资料数据表单
            uploadMaterialForm: {
                select_resources_type: '',
                select_course_class: '',
                title: '',
                resource_file_type: '',
                file: '',
                fileName: '',
                file_path: '',
                pdfFile: '',
                pdfName: '',
                file_preview_path: '',
            },
            uploadMaterialRules: {
                select_resources_type: [
                    {required: true, message: '请选择资源类型', trigger: 'change'}
                ],
                select_course_class: [
                    {required: true, message: '请选择课程分类', trigger: 'change'}
                ],
                file: [
                    {required: true, message: '请上传文件', trigger: 'change'}
                ],
                title: [
                    {required: true, message: '请输入标题', trigger: 'blur'},
                    {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
                ],
            },
            formLabelWidth: '100px',
            //课程分类
            adminResourcesClassList: [],
            resourcesClassProps: {
                children: 'children',
                label: 'name',
                value: 'index'
            },
            //资源类型
            adminResourcesTypeList: [],
            //是否要上传PDF 文件
            isUploadPDF: false,
            isPDFInput: false,
            typeList: [],
            newTypeList: [{
                id: 1,
                name: '知识点'
            }, {
                id: 2,
                name: "素材库"
            }, {
                id: 3,
                name: '案例库'
            }, {
                id: 4,
                name: '视频'
            }, {
                id: 5,
                name: '相关文章'
            }],
            newTypePic: [
                {
                    id: 1,
                    src: require('../../../assets/images/teacher/course/knowledge_point.png')
                }, {
                    id: 2,
                    src: require('../../../assets/images/teacher/course/sucai.png')
                }, {
                    id: 3,
                    src: require('../../../assets/images/teacher/course/anli.png')
                }, {
                    id: 4,
                    src: require('../../../assets/images/teacher/course/video.png')
                }, {
                    id: 5,
                    src: require('../../../assets/images/teacher/course/related_articles.png')
                }
            ],
            cKejian: [],
            cJiaocai: [],
            cWeike: [],
            cDonghua: [],
            cPeitao: [],
            cJiaoan: [],
            cZhishidian: [],
            cSucaiku: [],
            cAnliku: [],
            cPeiWeike: [],
            cXiangguanwenzhang: [],
            cPersonResource: [],
            testCenter: [],
            practiceCenter: [],
            resourceList: [],
            papersList: [],
            projectsList: [],
            courseContent: [],
            material_list: [],
            course_content: [],
            showList: true,
            saveTemplateShow: false,
            templateName: '',
            tempPoverShow: false,
            selectTemplateList: [],
        }
    },
    mounted() {
        this.courseInfo();
    },
    watch: {
        nodeIndex(d) {
            this.setNull();
        },
        chapterIndex(d) {
            this.setNull();
        },
        $route() {
            this.select_course_id = this.$route.query.course_id
        }
    },
    methods: {
        setNull() {
            let arr = new Array(14).fill({});
            this.$emit('handleList', arr);
            this.courseInfo();
        },
        courseInfo() {
            let param = {
                id: this.select_course_id,
                chapter: Number(this.chapterIndex) + 1,
                node: Number(this.nodeIndex) + 1
            }
            getDataByCourseID(param).then((res) => {
                if (res.code == 200) {
                    this.resourceList = this.getList(res.data, 1);
                    this.papersList = this.getList(res.data, 2);
                    this.projectsList = this.getList(res.data, 3);
                    //课件
                    this.cKejian = this.formatResources(1);
                    // console.log('1222', this.cKejian);
                    //教材
                    this.cJiaocai = this.formatResources(2);
                    // console.log('2', this.cJiaocai);
                    //微课
                    this.cWeike = this.formatResources(3);
                    // console.log('3', this.cWeike);
                    //配套材料
                    this.material_list = this.formatPeitao();
                    //教案
                    this.cJiaoan = this.formatResources(5);
                    this.cZhishidian = this.formatMaterial(6)//知识点
                    this.cSucaiku = this.formatMaterial(7)//素材库
                    this.cAnliku = this.formatMaterial(8)//案例库
                    this.cPeiWeike = this.formatMaterial(9)//微课
                    this.cXiangguanwenzhang = this.formatMaterial(10)//相关文章
                    this.handleShowPeiTao();

                    this.formatExams();
                    this.formatProject();
                    this.courseInfoCustom();
                }
            }).catch(err => {
                console.error(err)
            })
        },
        getList(arr, type) {
            let newArr = [];
            newArr = arr.filter(item => {
                return Number(item.type) == Number(type)
            })
            return newArr
        },
        courseInfoCustom(id) {
            let params = {
                course_id: this.select_course_id,
                chapter: JSON.stringify(Number(this.chapterIndex) + 1),
                node: JSON.stringify(Number(this.nodeIndex) + 1)
            }
            if (id) {
                params = {
                    id: id
                }
            }
            courseGetTemplateData(params).then(res => {
                if (res.data !== null && res.data.length && res.data.length > 0) {
                    this.course_content = res.data;
                    this.handleCourseCustom();
                } else {
                    this.fillAll();
                }
            }).catch(err => {
                console.error(err)
            })
        },
        handleCourseCustom() {
            let arr = new Array(14).fill({});
            //查询到的自定义排版
            this.course_content.forEach(item => {
                if (item.resource_type_id > 5) {
                    this.material_list.forEach(item1 => {
                        if (item1.id == item.id) {
                            arr[item.index] = item1;
                        }
                    })
                } else {
                    if (item.type == 1) {
                        this.resourceList.forEach(item1 => {
                            if (item1.id == item.id) {
                                arr[item.index] = item1;
                            }
                        })
                    } else if (item.type == 2) {
                        this.papersList.forEach(item2 => {
                            if (item2.id == item.id) {
                                arr[item.index] = item2;
                            }
                        })
                    } else if (item.type == 3) {
                        this.projectsList.forEach(item3 => {
                            if (item3.id == item.id) {
                                arr[item.index] = item3;
                            }
                        })
                    }
                }
            })
            this.$emit('handleList', arr);
            this.showList = false;
            this.showList = true;
        },
        //后期可优化，暂时这么写
        fillAll() {
            let arr = [];
            this.cKejian.forEach(item => {
                let tmp = {};
                if (item) {
                    tmp = item;
                    tmp.show = 1;
                    arr.push(tmp)
                }
            });
            this.cJiaocai.forEach(item => {
                let tmp = {};
                if (item) {
                    tmp = item;
                    tmp.show = 2;
                    arr.push(tmp)
                }
            });
            this.cWeike.forEach(item => {
                let tmp = {};
                if (item) {
                    tmp = item;
                    tmp.show = 3;
                    arr.push(tmp)
                }
            });
            this.cJiaoan.forEach(item => {
                let tmp = {};
                if (item) {
                    tmp = item;
                    tmp.show = 4;
                    arr.push(tmp)
                }
            });
            this.projectsList.forEach(item => {
                let tmp = {};
                if (item) {
                    tmp = item;
                    tmp.show = 6;
                    arr.push(tmp)
                }
            });
            this.papersList.forEach(item => {
                let tmp = {};
                if (item) {
                    tmp = item;
                    tmp.show = 7;
                    arr.push(tmp)
                }
            });
            this.cPersonResource.forEach(item => {
                let tmp = {};
                if (item) {
                    tmp = item;
                    arr.push(tmp)
                }
            });
            let len = arr.length;
            //因为我只画了14个格子
            if (len > 14) {
                arr.splice(14, len - 14)
            } else {
                let newArr = new Array(14 - len).fill({})
                arr.push.apply(arr, newArr)
            }
            this.$emit('handleList', arr)
        },
        //查询资源数
        toLists(rtype) {
            //平台资源 resource_type_id : 课件 => 1 , 教材 => 2, 微课 => 3, 动画 => 4, 配套材料 => 5, 教案 => 6
            //课程内容中的标识： 平台资源 => 3，小测 => 4，项目 => 5；配套材料（学校管理员和学校教师自己上传的资源）=> 6
            let type = Number(rtype);
            switch (type) {
                case 1:
                    return this.toDetailBefore(this.cKejian, '课件', 1, 3);
                    break;
                case 2:
                    return this.toDetailBefore(this.cJiaocai, '教材', 2, 3);
                    break;
                case 3:
                    return this.toDetailBefore(this.cWeike, '微课', 3, 3);
                    break;
                case 4:
                    return this.toDetailBefore(this.cJiaoan, '教学设计', 4, 3);
                    break;
                case 5:
                    return this.toDetailBefore(this.cPersonResource, '个人资源', 5, 3);
                    break;
                case 6:
                    return this.toDetailBefore(this.papersList, '实操训练', 7, 4);
                    break;
                case 7:
                    return this.toDetailBefore(this.projectsList, '考试训练', 8, 5);
                    break;
            }
        },
        toMaterial(rtype) {
            let type = Number(rtype);
            switch (type) {
                case 6:
                    return this.toDetailBefore(this.cZhishidian, '知识点', 1, 6);
                    break;
                case 7:
                    return this.toDetailBefore(this.cSucaiku, '素材库', 2, 6);
                    break;
                case 8:
                    return this.toDetailBefore(this.cAnliku, '案例库', 3, 6);
                    break;
                case 9:
                    return this.toDetailBefore(this.cPeiWeike, '微课', 4, 6);
                    break;
                case 10:
                    return this.toDetailBefore(this.cXiangguanwenzhang, '相关文章', 5, 6);
                    break;
            }
        },
        //判断是否只有一个资源
        toDetailBefore(resources, msg = '', type, csType) {
            let res = '';
            let obj = {};
            let arr = [];
            if (resources.length === 1) {
                // this.canotDragger(msg);
                res = true;
                obj = resources[0]
            } else {
                // this.setResourceListToVuex(resources, type, csType);
                res = false;
            }
            arr.push(res, obj)
            return arr;
        },
        canotDragger(msg) {
            this.$message({
                type: 'warning',
                message: `当前节下的${msg}只有一个，支持直接拖拽`,
                duration: 1000
            });
        },
        formatExams() {
            _.each(this.papersList, (item) => {
                item.show = 7;
            });
        },
        formatProject() {
            _.each(this.projectsList, (item) => {
                item.show = 6;
            });
        },
        formatPeitao() {
            let reslist = [];
            //格式化资源的属性名，这边是查出所有的资源
            _.each(this.resourceList, (item) => {
                let tmp = {}
                if (Number(item.resource_type_id) > 5) {
                    tmp = item;
                    tmp.show = 5;
                    reslist.push(tmp)
                }
            });
            return reslist;
        },
        formatResources(type) {
            let reslist = [];
            //格式化资源的属性名，这边是查出所有的资源
            _.each(this.resourceList, (item) => {
                let tmp = {}
                if (Number(item.resource_type_id) === Number(type)) {
                    tmp = item;
                    tmp.show = type;
                    if(type == 5){
                        tmp.show = 4;
                    }
                    reslist.push(tmp);
                }
            });
            return reslist;
        },
        formatMaterial(type) {
            let reslist = [];
            //格式化资源的属性名
            _.each(this.material_list, (item) => {
                let tmp = {}
                if (Number(item.resource_type_id) === Number(type)) {
                    tmp = item;
                    switch (item.resource_type_id) {
                        case 6:
                            tmp.icon = 1;
                            break;
                        case 7:
                            tmp.icon = 2;
                            break;
                        case 8:
                            tmp.icon = 3;
                            break;
                        case 9:
                            tmp.icon = 4;
                            break;
                        case 10:
                            tmp.icon = 5;
                            break;
                    }
                    reslist.push(tmp);
                }
            });
            return reslist;
        },
        getResourceList(index) {
            this.showResourseList = [];
            switch (index) {
                case 1:
                    this.showResourseList = this.cKejian;
                    break;
                case 2:
                    this.showResourseList = this.cJiaocai;
                    break;
                case 3:
                    this.showResourseList = this.cWeike;
                    break;
                case 4:
                    this.showResourseList = this.cJiaoan;
                    break;
                case 5:
                    this.$router.push({
                        path: '/teachingCenter/teacherMatingDetail',
                        query: {
                            course_id: this.select_course_id
                        }
                    })
                    break;
                case 6:
                    this.showResourseList = this.projectsList;
                    break;
                case 7:
                    this.showResourseList = this.papersList;
                    break;
            }
        },
        //跳到课件列表页
        goToCourseWare(item) {
            switch (item.type) {
                case 1:
                    let fileType = item.path.split(".")[item.path.split(".").length - 1].toLowerCase()
                    if (fileType === 'pdf' || fileType === 'doc' || fileType === 'docx') {
                        window.open(localStorage.getItem('hosturl') + 'pdf/' + item.id);
                    } else {
                        window.open(item.path, '_blank')
                    }
                    break;
                case 2:
                    this.$emit('changeShow', 2)
                    localStorage.setItem('paperList', JSON.stringify(this.papersList))
                    this.$router.push({
                        path: "/teachingCenter/examDetail",
                        query: {
                            id: item.id
                        }
                    })
                    break;
                case 3:
                    this.$emit('changeShow', 3)
                    localStorage.setItem('projectsList', JSON.stringify(this.projectsList))
                    this.$router.push({
                        path: "/teachingCenter/practiceDetail",
                        query: {
                            id: item.id
                        }
                    })
                    break
            }
        },
        onMove(evt) {
            let obj = evt.draggedContext.element;
            let index = evt.draggedContext.index + 1;
            let res = null;
            //多个的不允许拖拽
            res = this.toLists(index);
            if (res[0] == false) {
                return false;
            } else {
                obj = res[1]
            }
            let arr = JSON.parse(JSON.stringify(this.select_list))
            for (let i = 0; i < arr.length; i++) {
                if (Number(arr[i].id) === Number(obj.id)) {
                    return false;
                }
            }
            if (arr.includes(obj)) {
                return false;
            }
        },
        onMove1(evt) {
            let obj = evt.draggedContext.element;
            for (let i = 0; i < this.select_list.length; i++) {
                if (Number(this.select_list[i].id) === Number(obj.id)) {
                    return false;
                }
            }
        },
        onEnd(evt) {
            let index = evt.oldIndex + 1;
            let newIndex = evt.newIndex;
            let obj = {};
            let res = null;
            res = this.toLists(index);
            if (res[0] == true) {
                obj = res[1];
                if (!this.select_list.includes(obj)) {
                    this.select_list[newIndex] = obj;
                }
            }
            if (this.select_list.length > 14) {
                this.select_list.splice(newIndex + 1, 1)
            }
            this.module_list = this.select_list;
            this.$emit('handleList', this.select_list);
        },
        onEnd1(evt) {
            let index = evt.oldIndex;
            let obj = this.material_list[index];
            let newIndex = evt.newIndex;
            let newObj = {};
            let res = null;
            res = this.toMaterial(obj.resource_type_id);
            if (res[0] == true) {
                newObj = res[1];
                if (!this.select_list.includes(newObj)) {
                    this.select_list[newIndex] = newObj;
                }
            }
            if (this.select_list.length > 14) {
                this.select_list.splice(newIndex + 1, 1)
            }
            this.module_list = this.select_list;
            this.$emit('handleList', this.select_list);
        },
        handleSave(val){
            this.$emit('handleSave', val)
        },
        handleList(val){
            this.$emit('handleList', val);
        },
        handleTemp(val){
            this.courseInfoCustom(val);
        },
        updateTempList(val){
            this.$emit('updateTempList')
        },
        //删除模板
        changeType(id) {
            this.classId = id;
            let arr = [];
            this.material_list.forEach(item => {
                if (id === 0) {
                    arr.push(item)
                } else {
                    if (item.icon === id) {
                        arr.push(item)
                    }
                }
            })
            this.showPeitao = arr;
        },
        handleShowPeiTao() {
            let arr = [];
            let len = this.material_list.length;
            for (let i = 0; i < len; i++) {
                arr.push(this.material_list[i]);
            }
            this.showPeitao = arr;
        },
    }
}
